@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	@apply h-full w-full bg-bgColor;
}

body {
	@apply font-normal text-base bg-bgColor h-full w-full overflow-x-hidden;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}