
.imgCntnr{
   max-width: 100%;
   padding: 0 0px 0px 0px;
   margin-bottom: 32px;
}

.mainBlock{
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.promo__rotate{
   user-select: none;
}
.promo__rotate::before {
   content: '';
	position: absolute;
	height: 15px;
	width: 15px;
	background-color: blue;
	left: 50%;
	transform: translateX(-50%);
	top: -25px;
	cursor: url('https://findicons.com/files/icons/1620/crystal_project/16/rotate_ccw.png'),
		auto;
	border-radius: 50%;
}
.settingBtn{
	display: flex;
}
.configCntnr{
	width: 320px;
}
@media (min-width:1050px) {
	.settingBtn,.btnSend{
		display: none!important;
	}
}
@media (max-width:1049px) {
	.btnConfig{
		display: none!important;
	}
}


@media (max-width:351px) {
	.configCntnr{
		width: auto;
	}
}