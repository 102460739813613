@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');


input::-moz-placeholder { color: #6B6E7F!important;font-size: 14px; }

input::-webkit-input-placeholder { color: #6B6E7F!important;font-size: 14px; }

.mainPromo{
   font-family: 'Raleway';
   padding-bottom: 69px;
   min-height:100vh;
}

.localBtn{
   border: 2px solid ;
   border-radius: 47px;
}

.localContainer{
   width: 660px;
height: auto;
padding:32px;
border-radius: 8px;
}
.step1Btns{
   padding:0 20px;
}

.promoInput{
   border-radius: 16px;
}

.promoContainer{
   width: 644px;
height: 298px;
border-radius: 8px;
}


.radi{
   border-radius: 8px;
}

.btnPromo2{
   border-radius: 59px;
   height: 40px;
}
.btnPromoImg2{
   border-radius: 100%;
   

}

.btnPromo{
   border-radius: 59px;
   height: 50px;
}
.btnPromoImg{
   border-radius: 59px;
   width:50px;
   height: 50px;

}
.placesBody1{
   
   width: 100%;
   display: grid;
    grid-auto-rows: minmax(0px,260px); 
   /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr ; */
   grid-auto-columns: minmax(0,260px);
}
.placesBody2{
   width: 100%;
   display: grid;
   grid-auto-rows: minmax(0px,418px);

   grid-auto-columns: minmax(0px,535px);
}
.placesBody2 .flexPromo1:nth-child(1){
   
   grid-column: 1;
   grid-row: 1;
}


.placeContainer{
   border-radius: 16px;
   max-width: 1454px;
   margin: 0 10px;
   padding: 58px;
   overflow: hidden;
}

.flexPromo1:nth-child(1){
   
   grid-column: 1/3;
   grid-row: 1/3;
}
.flexPromo1:nth-child(2){
   grid-column: 3;
   grid-row: 1;
}
.flexPromo1:nth-child(3){
   grid-column: 3;
   grid-row: 2;
}
.flexPromo1:nth-child(4){
   grid-column:4/6;
   grid-row: 1/3;
}
.flexPromo1:nth-child(5){
   grid-column: 1;
   grid-row: 3;
}
.flexPromo1:nth-child(6){
   grid-column: 1;
   grid-row: 4;
}
.flexPromo1:nth-child(7){
   grid-column: 2/4;
   grid-row: 3/5;
}
.flexPromo1:nth-child(8){
   grid-column:4;
   grid-row: 3;
}
.flexPromo1:nth-child(9){
   grid-column:4;
   grid-row: 4;
}
.flexPromo1:nth-child(10){
   grid-column:5;
   grid-row: 3;
}
.flexPromo1:nth-child(11){
   grid-column:5;
   grid-row: 4;
}
.flexPromo1:nth-child(12){
   grid-column: 1;
   grid-row: 5;
}
.flexPromo1:nth-child(13){
   grid-column: 1;
   grid-row: 6;
}
.flexPromo1:nth-child(14){
   grid-column: 2;
   grid-row: 5;
}
.flexPromo1:nth-child(15){
   grid-column:2;
   grid-row: 6;
}
.flexPromo1:nth-child(16){
   grid-column:3/5;
   grid-row: 5/7;
}
.flexPromo1:nth-child(17){
   grid-column:5;
   grid-row: 5;
}
.flexPromo1:nth-child(18){
   grid-column:5;
   grid-row: 6;
} 






.title3{
   padding-bottom: 36px ;
}


.imgPlace{
   
   border-radius: 16px;
   overflow: hidden!important;
   width: 100% ;
   height: 100%;
   
}
.imgPlace__img{
   width:  100%;
   height: 100%;
   border-radius: 16px;
   object-fit: cover;
}
.placesCntnr{
   
   padding: 5px;
   height: 100%;
   width: 100%;
   max-width: 535px;
   max-height: 535px;
}
.mask-bg .placesCntnr{
   max-width: 100%;
   max-height: 100%;
}

.mask-bg .imgPlace{
   
   width: fit-content;
   height: 100%;

 }
.mask-bg .imgPlace__img{
   max-height: 75vh;
   max-width: 85vw;
   width: auto;
   height: auto;
   object-fit: contain;
}

.placesBody2 .mask-bg .placesCntnr {
   max-width: 100%;
   max-height: 100%;
}


.vidos-cntnr{
   display: block;
   border-radius: 16px;
   overflow: hidden;
}

.vidos{
   width:  100%;
   height: 100%;
   border-radius: 16px;
   object-fit: cover;
}

.mask-bg .vidos{
   border-radius: 16px;
   max-height: 75vh;
   max-width: 85vw;
   width: auto;
   height: auto;
   object-fit: contain;
}
.mask-bg{
   cursor: default;
   position: absolute;
   top: 0;
   left: 0;
   right:  0;
   bottom: 0;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.88);
   /* display: flex;
   align-items: center;
   justify-content: center; */
   
}
.mask-bg__container{
   position: fixed;
   top: 0;
   left: 0;
   right:  0;
   bottom: 0;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}


.mask-bgSm{
   cursor: default;
   position: fixed;
   top: 0;
   left: 0;
   right:  0;
   bottom: 0;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.9);
   display: flex;
   align-items: center;
   justify-content: center;
   
}


.mask-exit{
   transform: scaleY(0.8);
   font-weight: 600;
   position: absolute;
   top: -8px;
   right: -20px;
   padding:8px;
   cursor: pointer;
}
.mask-bg.active{
   
   z-index: 50!important;
   pointer-events: all!important;
   opacity: 100!important;
}
.mask-bgSm.active{
   
   z-index: 50!important;
   pointer-events: all!important;
   opacity: 100!important;
}

.header{
   max-width: 1740px;
   margin: 0 auto;
   
   padding: 31px 10px;
}
.localSection, .promoSection, .downloadSection{
   margin-top: 137px;
}
.footer{
   
   margin-top: 69px;
   padding: 31px 10px;
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
}
.footer-cntnr{
   max-width: 1720px;
   margin: 0 auto;
}

.smallGrid{
   opacity:0;
   position: fixed;
   pointer-events: none;
}


@media (max-width:639.5px) {
   
   .localContainer{
      width: 100%;
      height: auto;
   }
   .promoContainer{
      width: 100%;
   }


   
}




@media (max-width:1425px) {




   
   .localSection, .promoSection, .downloadSection{
      margin-top: 127px;
   }

   .placesBody1{
      grid-auto-columns: minmax(0,220px);
       grid-auto-rows: minmax(0px,220px); 
   }
   
   /* .placesBody2{
      margin: -5px;
      padding-top: 5px;
      display: grid;
      grid-auto-rows: minmax(200px,200px);
      grid-auto-columns: minmax(200px,400px);
      grid-template-columns:0.5fr 1fr 0.5fr 0.5fr  ;
      grid-template-rows: 200px 200px;
   
   }
   .placesBody3{
      padding-top: 5px;
      margin: -5px;
      
      display: grid;
      grid-auto-rows: minmax(200px,200px);
      grid-auto-columns: minmax(200px,400px);
      grid-template-columns:0.5fr 0.5fr 1fr 0.5fr  ;
      grid-template-rows: 200px 200px;
   
   }
   
   
   .placesBody4{
      margin: -5px;
      padding-top: 5px;
      display: grid;
      grid-auto-rows: minmax(200px,200px);
      grid-auto-columns: minmax(200px,400px);
      grid-template-columns:1fr 0.5fr 1fr ;
      grid-template-rows: 200px 200px;
   
   } */

}
@media (max-width:1300px) {
   .placesBody1{
      grid-auto-columns: minmax(0,200px);
      grid-auto-rows: minmax(0px,200px); 
  }
}

@media (max-width:1200px) {
   .placesBody1{
      grid-auto-columns: minmax(0,180px);
      grid-auto-rows: minmax(0px,180px); 
  }
}

@media (max-width:1131px) {
   .placesBody1{
      grid-auto-columns: minmax(0,160px);
      grid-auto-rows: minmax(0px,160px); 
   }
   .localSection, .promoSection, .downloadSection{
      margin-top: 104px;
   }
   /* .placesCntnrSm{
      border-radius: 16px;
      overflow: hidden;
      padding: 5px;
      min-width: 150px;
      min-height: 150px;
      
   
   }

   .placesBody1sm{
      margin: -5px;
      
      display: grid;
      grid-auto-rows: minmax(150px,150px);
      grid-auto-columns: minmax(150px,300px);
      grid-template-columns:1fr 0.5fr 1fr ;
      grid-template-rows: 150px 150px;
   
   }
   
   .placesBody2sm{
      margin: -5px;
      padding-top: 5px;
      display: grid;
      grid-auto-rows: minmax(150px,150px);
      grid-auto-columns: minmax(150px,300px);
      grid-template-columns:0.5fr 1fr 0.5fr 0.5fr  ;
      grid-template-rows: 150px 150px;
   
   } */
   

   
   
   /* .placesBody2{
      margin: -5px;
      padding-top: 5px;
      display: grid;
      grid-auto-rows: minmax(150px,150px);
      grid-auto-columns: minmax(150px,300px);
      grid-template-columns:0.5fr 1fr 0.5fr 0.5fr  ;
      grid-template-rows: 150px 150px;
   
   }
   .placesBody3{
      padding-top: 5px;
      margin: -5px;
      
      display: grid;
      grid-auto-rows: minmax(150px,150px);
      grid-auto-columns: minmax(150px,300px);
      grid-template-columns:0.5fr 0.5fr 1fr 0.5fr  ;
      grid-template-rows: 150px 150px;
   
   }
   
   
   .placesBody4{
      margin: -5px;
      padding-top: 5px;
      display: grid;
      grid-auto-rows: minmax(150px,150px);
      grid-auto-columns: minmax(150px,300px);
      grid-template-columns:1fr 0.5fr 1fr ;
      grid-template-rows: 150px 150px;
   
   } */

}

.bigGrid,.smallGrid{
   padding-bottom: 80px;
}
.bigGrid{
   opacity:1;
   pointer-events: all;
}


@media (max-width:1000px) {
   .placesBody1{
      grid-auto-columns: minmax(0,150px);
      grid-auto-rows: minmax(0px,150px); 
  }
}


@media (max-width:874px) {
   .listBG{
   
      background-color: #252535;
   }
   
   
   .title3{
      padding-bottom: 16px ;
   }
.placeContainer{
   padding: 28px;
}

   .header{
      padding-top:20px ;
      padding-bottom: 20px;
      
   }
   .localSection, .promoSection, .downloadSection{
      margin-top: 71px;
   }
   .footer{
      padding-top:20px ;
      padding-bottom: 20px;
   }
   .footer-cntnr{ 
      flex-direction: column;
   }
   .footer-link{
      margin-bottom: 20px;
   }
   .bigGrid{
      padding-bottom: 80px;
   }
   .placesBody1{
      grid-auto-columns: minmax(0,200px);
      grid-auto-rows: minmax(0px,200px); 
   }
  
   
   .placesBody2{
      grid-auto-rows: minmax(0,1fr) ;
   
      grid-auto-columns: minmax(0px,1fr);
   }
   .placesBody2 .placesCntnr {
      max-width: 100%;
      max-height: 400px;
   }
   .flexPromo1:nth-child(1){
      grid-column: 1/3;
      grid-row: 1/3;
   }
   .flexPromo1:nth-child(2){
      grid-column: 3;
      grid-row: 1;
   }
   .flexPromo1:nth-child(3){
      grid-column: 3;
      grid-row: 2;
   }
   .flexPromo1:nth-child(4){
      grid-column:1;
      grid-row: 3;
   }
   .flexPromo1:nth-child(5){
      grid-column: 1;
      grid-row: 4;
   }
    .flexPromo1:nth-child(6){
      grid-column: 2/4;
      grid-row: 3/5;
   }
   .flexPromo1:nth-child(7){
      grid-column: 1/3;
      grid-row: 5/7;
   }  
    .flexPromo1:nth-child(8){
      grid-column: 3;
      grid-row: 5;
   }
   .flexPromo1:nth-child(9){
      grid-column:3;
      grid-row: 6;
   }
   .flexPromo1:nth-child(10){
      grid-column:1;
      grid-row: 7;
   }
   .flexPromo1:nth-child(11){
      grid-column:1;
      grid-row: 8;
   }
   .flexPromo1:nth-child(12){
      grid-column: 2/4;
      grid-row: 7/9;
   }
   .flexPromo1:nth-child(13){
      grid-column: 1/3;
      grid-row: 9/11;
   }
   .flexPromo1:nth-child(14){
      grid-column: 3;
      grid-row: 9;
   }
   .flexPromo1:nth-child(15){
      grid-column:3;
      grid-row: 10;
   }
   .flexPromo1:nth-child(16){
      grid-column:1;
      grid-row: 11;
   }
   .flexPromo1:nth-child(17){
      grid-column:1;
      grid-row: 12;
   }
   .flexPromo1:nth-child(18){
      grid-column:2/4;
      grid-row: 11/13;
   }  
}


@media (max-width:700px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,206px); 
  }
}
@media (max-width:675px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,198px); 
  }
}
@media (max-width:650px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,200px); 
  }
}
@media (max-width:639.5px){
   
   .title3{
      padding-bottom: 8px ;
   }
   .bigGrid{
      width: 100vw !important;
   }
   .placeContainer{
      padding: 0;
      
      background: inherit!important;
   }
   
}
@media (max-width:596px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,195px); 
  }
}
@media (max-width:578px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,190px); 
  }

   /* .placeContainerSm{
      padding: 0;
      background: inherit!important;
   }
    .placesCntnr{
      border-radius: 16px;
      overflow: hidden;
      padding: 5px;
      min-width: 116px;
      min-height: 116px;
     
   
   }


   .placesBody1sm{
      margin: -5px;
      
      display: grid;
      grid-auto-rows: minmax(116px,116px);
      grid-auto-columns: minmax(116px,232px);
      grid-template-columns:232px 116px ;
      grid-template-rows: 116px 116px;
   
   }


   .placesBody2sm{
      margin: -5px;
      padding-top: 5px;
      display: grid;
      grid-auto-rows: minmax(116px,116px);
      grid-auto-columns: minmax(116px,232px);
      grid-template-columns:116px 232px  ;
      grid-template-rows: 116px 116px;
   
   }  */
}
@media (max-width:556px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,185px); 
  }
}
@media (max-width:538px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,170px); 
  }
}
@media (max-width:515px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,160px); 
  }
}

@media (max-width:480px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,150px); 
  }
}
@media (max-width:465px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,145px); 
  }
}
@media (max-width:450px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,135px); 
  }
}
@media (max-width:430px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,130px); 
  }
}
@media (max-width:400px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,120px); 
  }
}
@media (max-width:360px) {
   .step1Btns{
      padding:0 0px;
   }
   
  
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,110px); 
   }

   /* .placesBody1sm{
      margin: -5px;
      
      display: grid;
      grid-auto-rows: minmax(100px,100px);
      grid-auto-columns: minmax(100px,200px);
      grid-template-columns:200px 100px ;
      grid-template-rows: 100px 100px;
   
   }


   .placesBody2sm{
      margin: -5px;
      padding-top: 5px;
      display: grid;
      grid-auto-rows: minmax(100px,100px);
      grid-auto-columns: minmax(100px,200px);
      grid-template-columns:100px 200px  ;
      grid-template-rows: 100px 100px;
   
   }  */
}
@media (max-width:340px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,110px); 
  }
}
@media (max-width:330px) {
   .placesBody1{
      grid-auto-columns: minmax(0,0.5fr);
      grid-auto-rows: minmax(0px,100px); 
  }
}

.lds-default {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
 }
 .lds-default div {
   position: absolute;
   width: 6px;
   height: 6px;
   background: #49E146;
   border-radius: 50%;
   animation: lds-default 1.2s linear infinite;
 }
 .lds-default div:nth-child(1) {
   animation-delay: 0s;
   top: 37px;
   left: 66px;
 }
 .lds-default div:nth-child(2) {
   animation-delay: -0.1s;
   top: 22px;
   left: 62px;
 }
 .lds-default div:nth-child(3) {
   animation-delay: -0.2s;
   top: 11px;
   left: 52px;
 }
 .lds-default div:nth-child(4) {
   animation-delay: -0.3s;
   top: 7px;
   left: 37px;
 }
 .lds-default div:nth-child(5) {
   animation-delay: -0.4s;
   top: 11px;
   left: 22px;
 }
 .lds-default div:nth-child(6) {
   animation-delay: -0.5s;
   top: 22px;
   left: 11px;
 }
 .lds-default div:nth-child(7) {
   animation-delay: -0.6s;
   top: 37px;
   left: 7px;
 }
 .lds-default div:nth-child(8) {
   animation-delay: -0.7s;
   top: 52px;
   left: 11px;
 }
 .lds-default div:nth-child(9) {
   animation-delay: -0.8s;
   top: 62px;
   left: 22px;
 }
 .lds-default div:nth-child(10) {
   animation-delay: -0.9s;
   top: 66px;
   left: 37px;
 }
 .lds-default div:nth-child(11) {
   animation-delay: -1s;
   top: 62px;
   left: 52px;
 }
 .lds-default div:nth-child(12) {
   animation-delay: -1.1s;
   top: 52px;
   left: 62px;
 }
 @keyframes lds-default {
   0%, 20%, 80%, 100% {
     transform: scale(1);
   }
   50% {
     transform: scale(1.5);
   }
 }
 


 .resSM{
   width: max-content;
   height: max-content;
 }


.listBG{
   
   
   position: absolute;
   top: 0px;
   left: -4px;
   height: 102%;
   right:-5px;
   z-index: -1;
   border-bottom-left-radius: 8px;
   
}
 /* .code-cntnr{
   
   filter: blur(0px);
   backdrop-filter: blur(0px);
   opacity: 1;
   transition: opacity 0.2s;
 }
 .code-cntnr.noactive{
   transition: opacity 0s;
   filter: blur(12px);
   backdrop-filter: blur(12px);
   opacity: 0.4; 
}

.code-cntnr.noactive .code-cntnr__code{
      opacity: 0;
      transition: opacity 0.2s;
   }
.code-cntnr .code-cntnr__code{
      opacity: 1;
   } */

.promoContainer__div{
   width: 280px;
}
@media (max-width:360px) {
   .promoContainer__div{
      width: 260px;
   }
}